<template>
  <div>
    <a-modal
      :visible="visible"
      title="产品（物模型）选择"
      width="60%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <div class="product-operations">
        <div class="data-filter">
          <a-select
            style="margin-right: 1em"
            show-search
            allow-clear
            placeholder="产品协议"
            v-model="protocolsSearch"
            class="search-keyword"
          >
            <a-select-option
              v-for="protocol in metaData.productProtocols"
              :key="protocol.value"
              :value="protocol.value"
            >
              {{ protocol.name }}
            </a-select-option>
          </a-select>
          <a-select v-if="filterSelected" v-model="filterSelected" style="width: 120px">
            <a-icon slot="suffixIcon" type="smile" />
            <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
              {{ filter.label }}
            </a-select-option>
          </a-select>
          <a-input v-model="searchKeyword" class="search-keyword" clearable @keyup.enter.native="doSearch"></a-input>
          <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
        </div>
      </div>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :loading="tableLoading"
        :pagination="false"
        :data-source="tableDate"
        class="product-table"
        rowKey="productId"
        childrenColumnName="none"
        tableLayout="fixed"
      >
        <a-table-column title="产品ID" key="productId" align="center">
          <template slot-scope="scope">{{ scope.productId }}</template>
        </a-table-column>
        <a-table-column title="产品名称" key="name" align="center">
          <template slot-scope="scope">{{ scope.name }}</template>
        </a-table-column>
        <a-table-column title="中控内部驱动标识符" key="driver" align="center">
          <template slot-scope="scope">{{ scope.driver }}</template>
        </a-table-column>
        <a-table-column title="子设备数量" key="childrenCount" align="center">
          <template slot-scope="scope">{{ scope.childrenCount }}</template>
        </a-table-column>
        <a-table-column title="产品控制协议类型" key="protocol" align="center">
          <template slot-scope="scope">{{ $constRepository.product.ALL_PRODUCT_PROTOCOL[scope.protocol] }}</template>
        </a-table-column>
        <a-table-column title="产品类型" key="type" align="center">
          <template slot-scope="scope">{{ $constRepository.product.PRODUCT_TYPE[scope.type] }}</template>
        </a-table-column>
        <a-table-column title="用户是否可见" key="userVisible" align="center">
          <template slot-scope="scope">
            <div v-if="scope.userVisible">是</div>
            <div v-else>否</div>
          </template>
        </a-table-column>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';

export default {
  name: 'ProductSelectModal',
  components: {},
  props: ['propsMetaData'],
  data() {
    return {
      visible: false,
      selectType: 'radio',
      resultCallBack: {
        onSubmit: Function,
      },
      metaData: [],
      protocolsSearch: undefined,
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      tableLoading: false,
      requestParams: {},
      selectedRowKeys: [],
      tableDate: [],
      filterSelected: this.$constRepository.product.FILTER_OPTION_PRODUCT_NAME.value,
      filterOptions: this.$constRepository.product.FILTER_OPTIONS,
      searchKeyword: '',
    };
  },
  methods: {
    async listProducts() {
      this.tableLoading = true;
      try {
        const params = {
          name: this.requestParams.name,
          productId: this.requestParams.productId,
          driver: this.requestParams.driver,
          protocol: this.requestParams.protocol,
          internalModelIdentifier: this.requestParams.internalModelIdentifier,
          type: this.requestParams.type,
          detail: true,
          page: this.pageInfo.page - 1,
          size: this.pageInfo.size,
        };
        const response = await this.$apiManager.product.getProducts(params);
        this.tableDate = response.data.elements;
        this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
        this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
        this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
      } finally {
        this.tableLoading = false;
      }
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'productId', undefined);
      this.$set(this.requestParams, 'driver', undefined);
      this.$set(this.requestParams, 'protocol', undefined);
      this.$set(this.requestParams, 'internalModelIdentifier', undefined);
      if (this.protocolsSearch) {
        this.$set(this.requestParams, 'protocol', this.protocolsSearch);
      }
      switch (this.filterSelected) {
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_ID.value:
          this.$set(this.requestParams, 'productId', this.searchKeyword);
          break;
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_DRIVER.value:
          this.$set(this.requestParams, 'driver', this.searchKeyword);
          break;
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_MODEL.value:
          this.$set(this.requestParams, 'internalModelIdentifier', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
        this.processSearchKeyword();
        await this.listProducts();
        if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
          this.pageInfo.page = 1;
          await this.listProducts();
        }
        this.tableLoading = false;
    },
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    async showDialog(callback, typeFilter) {
      if (typeFilter) {
        this.$set(this.requestParams, 'type', typeFilter);
      }
      this.resultCallBack.onSubmit = callback;
      this.listProducts();
    if (!this.propsMetaData) {
      await this.getMetaData();
    } else {
      this.metaData = this.propsMetaData;
    }
      this.visible = true;
    },
    onSubmit() {
      if (this.selectedRowKeys[0]) {
        const product = this.tableDate.find((productSelect) => productSelect.productId === this.selectedRowKeys[0]);
        this.resultCallBack.onSubmit(product);
        this.hideDialog();
      } else {
        message.warn('请选择管理产品（物模型）');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.product-operations {
  display: flex;
  justify-content: flex-end;
  .add-btn {
    // margin: 10px 0px;
    // margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      // margin-left: 3%;
    }
  }
  .product-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
